import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }
  getJobBilling(id) {
    const params = new HttpParams().set('booking', id);
    return this.http.get(`${this.url}user/bills/`, {params}).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response['data'];
        }
      )
    );
  }

  createPrice(data) {
    return this.http.post(`${this.url}bill/${data.category}/create`, data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  createCallout(data) {
    return this.http.post(`${this.url}bill/fee`, data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  editCallOut(data) {
    return this.http.put(`${this.url}bill/fee`, data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  getCallout() {
    return this.http.get(`${this.url}bill/fee`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  getAllPricing(data) {
    const serviceParameter = new HttpParams().set('service', data.service);
    return this.http.get(`${this.url}bill/${data.category}?page=${data.page}&perPage=10`, {params: serviceParameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(response);
          return response;
        }
      )
    );
  }
  deletePrice(data, category) {
    console.log(category);
    console.log(data);
    return this.http.delete( `${this.url}bill/${category}/${data.id}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }

  editPrice(data) {
    console.log(data.category);
    return this.http.put(`${this.url}bill/${data.category}`, data).pipe(
      map(
        (response) => {
          return response;
        }
      )
    );
  }

}
