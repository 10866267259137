import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../shared/services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JobService} from '../shared/services/job.service';

class SearchModel {
  search: string;
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})

export class CustomerDetailsComponent implements OnInit {
  user;
  name;
  userData;
  userJobs;
  jobCountData;
  jobCount;
  id;
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  model = new SearchModel();
  clicked;
  @ViewChild('searchForm', {static: false}) form;
  constructor(private userService: UserService, private jobService: JobService,
              private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.id = params.get('id');
      this.getUserDetails(id);
      this.getUserJobsCount({user: id});
    });
    this.route.queryParams.subscribe(params => {
      this.getUserBookingDetails(this.id, params.page || '1', this.perPage || 2);
    });
  }

  getUserDetails(id) {
    this.userService.getUser(id).subscribe(
      data => {
        this.user = data;
        this.user.image = (this.user.image) ? this.user.image : 'no-pic.svg';
        // console.log(this.user);
        this.name = this.user.fullName;
        this.name = this.name.split(' ');
        // console.log(this.name);
      }
    );
  }
  getUserBookingDetails(userid, page, perPage) {
    this.jobService
      .getUserBookingDetails(userid, page, perPage)
      .subscribe(
      data => {
        this.userData = data;
        this.userJobs = this.userData.data;
        // console.log( this.userJobs);
        this.pager = this.userData.pagination;
        this.pageOfItems = this.userData.pageOfItems;
      }
    );
  }
  getUserJobsCount(id) {
    this.jobService.getJobsCount(id).subscribe(
      data => {
        this.jobCountData = data;
        this.jobCount = this.jobCountData.count;
      });
  }
  onSearch() {
    this.clicked = true;
    // console.log(this.form.value);
    const data = this.form.value;
    data['user'] = this.id;
    this.jobService.searhUserJobs(data).subscribe(
      response => {
        this.clicked = false;
        this.userData = response;
        this.userJobs = this.userData.data;
        // console.log( this.userData);
        this.pager = this.userData.pagination;
        this.pageOfItems = this.userData.pageOfItems;
      });
  }


}
