import { Injectable } from '@angular/core';

// @Injectable({
  // providedIn: 'root'
// })
@Injectable()
export class CsvService {

  constructor() { }

  downloadFile(data, filename = 'data', arrHeader, newHeaders) {
    const csvData = this.convertToCsv(data, arrHeader, newHeaders);
    console.log(csvData);
    const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', `${filename}.csv`);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  convertToCsv(objArray, headerList, newHeaders) {
    console.log(objArray);
    console.log(headerList);
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    console.log(array);

    for (const index in newHeaders) {
      row += newHeaders[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (const index in headerList) {
        const head = headerList[index];

        line += ',' + this.strRep(array[i][head]);
      }
      str += line + '\r\n';
    }
    return str;
  }

  strRep(data) {
    if (typeof data === 'string') {
      const newData = data.replace(/,/g, ' ');
      return newData;
    } else if (typeof data === 'undefined') {
      return '-';
    } else if (typeof data === 'number') {
      return  data.toString();
    } else {
      return data;
    }
  }
}
