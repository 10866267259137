import { Component, OnInit } from '@angular/core';
import {JobService} from '../../shared/services/job.service';
import {UserService} from '../../shared/services/user.service';

@Component({
  selector: 'app-account-stats',
  templateUrl: './account-stats.component.html',
  styleUrls: ['./account-stats.component.css']
})
export class AccountStatsComponent implements OnInit {
  totalJobs;
  totalParts;
  totalPart;
  totalAmount;
  totalServiceCharges;
  totalServiceCharge;
  totalCallFees;
  totalCallFee;
  total;
  constructor(private jobService: JobService, private userService: UserService) { }

  ngOnInit() {
    this.getAllJobsCount();
    this.getTotalAmount();
    this.getPartsTotal();
    this.getServiceChargeTotal();
    this.getCallFeeTotal();
  }
  getAllJobsCount() {
    this.jobService.getAllJobsCount().subscribe(
      data => {
        this.totalJobs = data;
      });
  }

  getTotalAmount() {
    const send = {charge: 1};
    this.userService.getTotalAmount(send).subscribe(
      data => {
        this.totalAmount = data;
        this.total = this.totalAmount.result[0].total;
      });
  }
  getPartsTotal() {
    const send = {charge: 2};
    this.userService.getTotalAmount(send).subscribe(
      data => {
        this.totalParts = data;
        this.totalPart = this.totalParts.result[0].total;
      });
  }
  getServiceChargeTotal() {
    const send = {charge: 3};
    this.userService.getTotalAmount(send).subscribe(
      data => {
        this.totalServiceCharges = data;
        this.totalServiceCharge = this.totalServiceCharges.result[0].total;
      });
  }
  getCallFeeTotal() {
    const send = {charge: 4};
    this.userService.getTotalAmount(send).subscribe(
      data => {
        this.totalCallFees = data;
        this.totalCallFee = this.totalCallFees.result[0].total;
      });
  }

}
