import {Component, OnInit, ViewChild} from '@angular/core';
import {JobService} from '../shared/services/job.service';
import {ActivatedRoute, Router} from '@angular/router';
class SearchModel {
  search: string;
}
class PerPageModel {
  number: string;
}
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  emptyJobsMessage;
  data = {};
  jobData;
  jobs;
  // perPage = new PerPageModel();
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  model = new SearchModel();
  clicked;
  @ViewChild('searchForm', {static: false}) form;
  constructor(private jobService: JobService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getRecentJobs(params.page || '1', this.perPage || 10);
    });
  }

  getRecentJobs(page, perPage) {
    this.jobService.getAllJobs(page, perPage).subscribe(
      response => {
        this.jobData = response;
        // console.log(this.jobData);
        this.jobs = this.jobData.data;
        this.pager = this.jobData.pagination;
        this.pageOfItems = this.jobData.pageOfItems;
      });
  }

  onSearch() {
    // console.log(this.form.value);
    const data = this.form.value;
    this.jobService.searchJobs(data).subscribe(
      response => {
        this.jobData = response;
        this.jobs = this.jobData.data;
        this.pager = this.jobData.pagination;
        this.pageOfItems = this.jobData.pageOfItems;
      });
  }

  pperPage(event) {
    this.perPage = event;
    this.getRecentJobs(1, event);
  }

  getFilteredJobs(event) {
    this.jobs = event.data;
    this.emptyJobsMessage = 'Filter Result: 0 No matching records.';
  }
}
