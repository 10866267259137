import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StatModule} from './stat/stat.module';
// import { GraphComponent } from './graph/graph.component';
import { PieComponent } from './pie/pie.component';
import { BarComponent } from './bar/bar.component';


@NgModule({
  declarations: [PieComponent, BarComponent],
  imports: [
    CommonModule, StatModule
  ],
    exports: [PieComponent, BarComponent]
})
export class DashboardModule { }
