import { Component, OnInit } from '@angular/core';
import {JobService} from '../../shared/services/job.service';
import {UserService} from '../../shared/services/user.service';
import {TechnicianService} from '../../shared/services/technician.service';
import {PasswordValidators} from '../../shared/validators/password.validators';
import {EmailValidators} from '../../shared/validators/email.validators';
import {FormBuilder, Validators} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {ServiceService} from '../../shared/services/service.service';

@Component({
  selector: 'app-servicemen-stat',
  templateUrl: './servicemen-stat.component.html',
  styleUrls: ['./servicemen-stat.component.css']
})
export class ServicemenStatComponent implements OnInit {
  techData;
  technician;
  technicianAcData;
  technicianAc;
  technicianGenData;
  technicianGen;
  form;
  clicked;
  response;
  file: File = null;
  services;

  // tslint:disable-next-line:max-line-length
  constructor(fb: FormBuilder, private router: Router, private serviceService: ServiceService, private technicianService: TechnicianService, private toastrService: ToastrService) {
    this.form = fb.group({
      type: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[^ @]*@[^ @]*'), EmailValidators.cannotContainSpace],
      ],
      phone: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('[0-9]+')]
      ],

    });
  }

  ngOnInit() {
    this.getAllTechniciansCount();
    this.getAllTechniciansCountwithType();
    this.getAllServices();
  }

  onChange(event) {
    this.file = event.target.files[0];
  }

  getAllTechniciansCount() {
    this.technicianService.getAllTechniciansCount().subscribe(
      response => {
        this.techData = response;
        this.technician = this.techData.count;
      });
  }
  getAllTechniciansCountwithType() {
    this.technicianService.getAllTechniciansCountWithType('Ac').subscribe(
      response => {
        this.technicianAcData = response;
        this.technicianAc = this.technicianAcData.count;
      });
    this.technicianService.getAllTechniciansCountWithType('Generator').subscribe(
      response => {
        this.technicianGenData = response;
        this.technicianGen = this.technicianGenData.count;
      });
  }

  prepareData() {
    const data = this.form.value;
    data.fullName = this.form.controls['fname'].value + ' ' + this.form.controls['lname'].value;
    // data.type = 'Generator';
    delete data.fname;
    delete data.lname;
    return data;
  }
  addServicemen() {
    this.clicked = true;
    const data = this.prepareData();
    // console.log(data);
    // console.log(data.fullName);
    this.technicianService.addServicemen(data, this.file).subscribe(
      response => {
        this.response = response;
        this.clicked = false;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          // this.closeModal();
          return this.router.navigate(['/servicemen']);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        // console.log(error);
        this.clicked = false;
      }
    );
  }

  getAllServices() {
    this.serviceService.getAllServices().subscribe(
      response => {
        this.response = response;
        this.services = this.response.data;
      });
  }

  get fname() {
    return this.form.get('fname');
  }
  get lname() {
    return this.form.get('lname');
  }
  get phone() {
    return this.form.get('phone');
  }
  get email() {
    return this.form.get('email');
  }
  get type() {
    return this.form.get('type');
  }


}
