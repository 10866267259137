import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
class PerPageModel {
  number: string;
}
@Component({
  selector: 'app-perpage',
  templateUrl: './perpage.component.html',
  styleUrls: ['./perpage.component.css']
})
export class PerpageComponent implements OnInit {
  @Output('perPages') perPaged = new EventEmitter();
  @ViewChild('perPageForm', {static: false}) formed;
  formPerPage = new PerPageModel();
  constructor() { }

  ngOnInit() {
  }
  pperPage() {
    console.log(this.formed.value.number);
    const value =  this.formed.value.number;
    this.perPaged.emit(value);
  }

}
