import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {JobService} from '../shared/services/job.service';
import {ActivatedRoute, Router} from '@angular/router';
import {isEmpty} from 'rxjs/operators';
import {ExcelService} from '../shared/services/excel.service';
import * as XLSX from 'xlsx';

// import {CsvService} from '../shared/services/csv.service';
// import jsPDF from 'jspdf';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import htmlToPdfmake from 'html-to-pdfmake';

class SearchModel {
  search: string;
}
@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AccountingComponent implements OnInit {
  emptyJobsMessage = 'No Job yet';
  data = {};
  jobData;
  jobs;
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  model = new SearchModel();
  clicked;
  accounting = {};
  callouts = {};
  partsJson = {};
  serviceJson = {};

  @ViewChild('searchForm', {static: false}) form;

  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  @ViewChild('accountTable', {static: false}) accountTable: ElementRef;
  @ViewChild('calloutTable', {static: false}) calloutTable: ElementRef;
  @ViewChild('serviceTable', {static: false}) serviceTable: ElementRef;
  @ViewChild('partsTable', {static: false}) partsTable: ElementRef;

  constructor(private jobService: JobService, private route: ActivatedRoute,
              private excelService: ExcelService,  private router: Router) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getRecentJobs(params.page || '1', this.perPage || 10);
    });
  }

  getAccountingJson(accounting) {
    return accounting.map(
      (accountingAll, i) => {
      const {bookingId, date, name, total, bill: {call_fee = ''} = '',
        bill: {servicing_total = ''} = '', bill: {parts_total = ''} = ''} = accounting[i];
      accountingAll = {bookingId, date, name, total, call_fee, servicing_total, parts_total};
      return accountingAll;
    });
  }
  getCalloutJson(accounting) {
    return accounting.map((callFee, i) => {
      const {bookingId, date, name, bill: {call_fee = ''} = ''} = accounting[i];
      callFee = {bookingId, date, name, call_fee};
      return callFee;
    });
  }
  getServiceJson(accounting) {
    return accounting.map((servicing, i) => {
      const {bookingId, date, name, bill: {servicing_total = ''} = ''} = accounting[i];
      servicing = {bookingId, date, name, servicing_total};
      return servicing;
    });
  }
  getPartsJson(accounting) {
    return accounting.map((parts, i) => {
      const {bookingId, date, name, bill: {parts_total = ''} = ''} = accounting[i];
      parts = {bookingId, date, name, parts_total};
      return parts;
    });
  }

  getRecentJobs(page, perPage) {
    this.jobService.getAllJobsAccount(page, perPage).subscribe(
      response => {
        this.jobData = response;
        // console.log(this.jobData);
        this.jobs = this.jobData.data;
        this.pager = this.jobData.pagination;
        this.pageOfItems = this.jobData.pageOfItems;
      }
      );
  }
  // exportAccountCsv(accountJson) {
  //   const arrHeader = ['bookingId', 'date', 'name', 'call_fee',
  //     'servicing_total', 'parts_total', 'total'];
  //   const newHeaders = ['ID', 'Date', 'ServiceType', 'CalloutAmount', 'ServiceCharge', 'PartsAmount', 'SumTotal'];
  //   this.csvService.downloadFile(accountJson, 'Accounting', arrHeader, newHeaders);
  // }
  // exportServiceCsv(serviceJson) {
  //   const arrHeader = ['bookingId', 'date', 'name',
  //     'servicing_total'];
  //   const newHeaders = ['ID', 'Date', 'ServiceType', 'ServiceCharge'];
  //   this.csvService.downloadFile(serviceJson, 'Service', arrHeader, newHeaders);
  // }
  // exportPartsCsv(serviceJson) {
  //   const arrHeader = ['bookingId', 'date', 'name', 'parts_total'];
  //   const newHeaders = ['ID', 'Date', 'ServiceType', 'PartsAmount'];
  //   this.csvService.downloadFile(serviceJson, 'Parts', arrHeader, newHeaders);
  // }
  // exportCallFeeCsv(serviceJson) {
  //   const arrHeader = ['bookingId', 'date', 'name', 'call_fee'];
  //   const newHeaders = ['ID', 'Date', 'ServiceType', 'PartsAmount'];
  //   this.csvService.downloadFile(serviceJson, 'CallFee', arrHeader, newHeaders);
  // }
  exportExcel(tableName): void {
    /* table id is passed over here */
    const fileName = `fix234-${tableName}.xlsx`;

    const element = document.getElementById(tableName);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
//   public downloadAccountAsPDF() {
//     const doc = new jsPDF();
//     const accountTable = this.accountTable.nativeElement;
//     const html = htmlToPdfmake(accountTable.innerHTML);
//     const documentDefinition = { content: html };
//     pdfMake.createPdf(documentDefinition).open();
//   }
//   public downloadCallOutAsPDF() {
//     const doc = new jsPDF();
//     const calloutTable = this.calloutTable.nativeElement;
//     const html = htmlToPdfmake(calloutTable.innerHTML);
//     const documentDefinition = { content: html };
//     pdfMake.createPdf(documentDefinition).open();
//   }
//   public downloadServiceAsPDF() {
//     const doc = new jsPDF();
//     const serviceTable = this.serviceTable.nativeElement;
//     const html = htmlToPdfmake(serviceTable.innerHTML);
//     const documentDefinition = { content: html };
//     pdfMake.createPdf(documentDefinition).open();
//   }
//   public downloadPartsAsPDF() {
//     const doc = new jsPDF();
//     const partsTable = this.partsTable.nativeElement;
//     const html = htmlToPdfmake(partsTable.innerHTML);
//     const documentDefinition = { content: html };
//     pdfMake.createPdf(documentDefinition).open();
//   }

  // public downloadAsPDFo(mmm) {
  //   const doc = new jsPDF();
  //   console.log(this.pdfTable);
  //   console.log(this.pdfTable.nativeElement);
  //   const pdfTable = this.pdfTable.nativeElement;
  //
  //   var html = htmlToPdfmake(pdfTable.innerHTML);
  //
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).open();
  //
  // }
  // public downloadAsPDF(table) {
  //   const doc = new jsPDF();
  //   console.log(table);
  //   console.log(table.nativeElement);
  //   const pdfTable = table.nativeElement;
  //
  //   const html = htmlToPdfmake(pdfTable.innerHTML);
  //
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).open();
  // }

  exportAsXLSX(data) {
    this.excelService.exportAsExcelFile(data, 'Accounting');
  }


  getFilteredJobs(event) {
    this.jobs = event.data;
    this.emptyJobsMessage = 'Filter Result: 0 No matching records.';
  }

}
