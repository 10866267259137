import { Component, OnInit } from '@angular/core';
import {JobService} from '../../shared/services/job.service';
import {UserService} from '../../shared/services/user.service';
import {TechnicianService} from '../../shared/services/technician.service';

@Component({
  selector: 'app-job-stats',
  templateUrl: './job-stats.component.html',
  styleUrls: ['./job-stats.component.css']
})
export class JobStatsComponent implements OnInit {
  totalJobs: any;
  totalPendingJobs;
  totalCompletedJobs;
  totalCancelledJobs;
  totalAcJobs;
  totalGenJobs;
  pendingJobs;
  CompletedJobs;
  CancelledJobs;
  acJobs;
  genJobs;
  constructor(private jobService: JobService, private userService: UserService, private technicianService: TechnicianService) { }
  pending = {status: 'pending'};
  completed = {status: 'completed'};
  cancelled = {status: 'cancelled'};
  ac = {name: 'Air-Conditioner'};
  gen = {name: 'Generator'};

  ngOnInit() {
    this.getAllJobsCount();
    this.getJobsPendingCount();
    this.getJobsCompletedCount();
    this.getJobsCancelledCount();
    this.getJobsAcCount();
    this.getJobsGenCount();
  }
  getAllJobsCount() {
    this.jobService.getAllJobsCount().subscribe(
      data => {
        this.totalJobs = data;
      });
  }

  getJobsPendingCount() {
    this.jobService.getJobsCount(this.pending).subscribe(
      data => {
        this.totalPendingJobs = data;
        this.pendingJobs = this.totalPendingJobs.count;
      });
  }
  getJobsCompletedCount() {
    this.jobService.getJobsCount(this.completed).subscribe(
      data => {
        this.totalCompletedJobs = data;
        this.CompletedJobs = this.totalCompletedJobs.count;
      });
  }
  getJobsCancelledCount() {
    this.jobService.getJobsCount(this.cancelled).subscribe(
      data => {
        this.totalCancelledJobs = data;
        this.CancelledJobs = this.totalCancelledJobs.count;
      });
  }
  getJobsAcCount() {
    this.jobService.getJobsCount(this.ac).subscribe(
      data => {
        this.totalAcJobs = data;
        this.acJobs = this.totalAcJobs.count;
      });
  }
  getJobsGenCount() {
    this.jobService.getJobsCount(this.gen).subscribe(
      data => {
        this.totalGenJobs = data;
        this.genJobs = this.totalGenJobs.count;
      });
  }
}
