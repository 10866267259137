import {Component, OnInit, ViewChild} from '@angular/core';
import {BillingService} from '../../shared/services/billing.service';
import {ToastrService} from 'ngx-toastr';

// tslint:disable-next-line:class-name
class mentModel {
  price: string;
  id: string;
}

@Component({
  selector: 'app-create-callout-fee',
  templateUrl: './create-callout-fee.component.html',
  styleUrls: ['./create-callout-fee.component.css']
})
export class CreateCalloutFeeComponent implements OnInit {
  @ViewChild('createCalloutForm', {static: false}) form;
  response;
  calloutResponse;
  model = new mentModel();
  clicked: boolean = false;
  constructor(private billService: BillingService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.getCalloutFees();
  }
  createCallout() {
    this.billService.createCallout(this.form.value).subscribe(
      response => {
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          this.form.reset();
        } else {
          this.toastrService.error(this.response.message);
        }
      });
  }
  editCallOut() {
    this.billService.editCallOut(this.form.value).subscribe(
      response => {
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          // this.form.reset();
        } else {
          this.toastrService.error(this.response.message);
        }
      });
  }
  getCalloutFees() {
    this.billService.getCallout().subscribe(
      response => {
        this.calloutResponse = response;
        console.log(this.calloutResponse.data);
      },
      () => {},
      () => {
        this.model.price = this.calloutResponse.data[0].price;
        this.model.id = this.calloutResponse.data[0]._id;
        console.log(this.model.price);
        console.log(this.model.id);
      });
  }

}
