import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {JobService} from '../../services/job.service';
import {FormBuilder, Validators} from '@angular/forms';
import {EmailValidators} from '../../validators/email.validators';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TechnicianService} from '../../services/technician.service';
class LoginModel {
  // bookingId: bigint;
  technicianId: bigint;
}

@Component({
  selector: 'app-assign-servicemen',
  templateUrl: './assign-servicemen.component.html',
  styleUrls: ['./assign-servicemen.component.css']
})
export class AssignServicemenComponent implements OnInit {
  response;
  clicked;
  // form;
  model = new LoginModel();
  @ViewChild('assignForm', {static: false}) form;
  @Input() jobID;
  @Input() reassign;
  @Input() assignedServiceMen = [];
  tech;
  techToAssign =  [];
  techAssigned =  [];
  saving = false;

  constructor(private jobService: JobService, fb: FormBuilder,  private router: Router,
              private toastrService: ToastrService, private techService: TechnicianService) {}

  ngOnInit() {
    // console.log(this.jobID);
    this.getTechnicians();
  }

  onSubmit() {
    return;
    this.clicked = true;
    const data = this.form.value;
    data.bookingId = this.jobID;
    if (this.reassign) {
      this.reassignJob(data);
    } else {
      this.assignJob(data);
    }
  }
  assignJob(data) {
    this.jobService.assignServicemen(data).subscribe(
      response => {
        this.response = response;
        this.clicked = false;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          return this.router.navigate(['/jobs']);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );
  }
  reassignJob(data) {
    data.status = true;
    this.jobService.reassignServicemen(data).subscribe(
      response => {
        this.response = response;
        this.clicked = false;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          return this.router.navigate(['/jobs']);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>) => {
        this.toastrService.error(error.statusText);
        console.log(error);
        this.clicked = false;
      }
    );
  }
  getTechnicians() {
    this.techService.getAllAvailableTechnicians().subscribe(
      response => {
        this.tech = response;
        console.log(this.tech)
      });
  }
  async assignTechnictian(){
    this.saving = true;
    if(this.techToAssign.length > 0){

      for(let i=0;i<this.techToAssign.length;i++){
        const response = await this.jobService.assignAssistant(this.techToAssign[i]).toPromise();
        console.log(response)
      }
      this.toastrService.success(this.response.message);
      (<HTMLButtonElement>document.getElementById("cncID")).click();
    }
  }
  addTech(t, index){
    let cc = this.techToAssign.find(x=>x._id == t._id);
    if(!cc){
      console.log("-------");
      this.techToAssign.push({
        bookingId: this.jobID,
        technicianId: t._id,
        statusNumber: 1
      });
    }else{
      console.log("++++++++");
      this.techToAssign = this.techToAssign.filter(x=>x._id !== t._id).map(
        (t) => {
          return {
            bookingId: this.jobID,
            technicianId: t._id,
            statusNumber: 1
          }
        }
      );
    }
    console.log(this.techToAssign)
  }

}
