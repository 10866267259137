import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }
  sendBroadcast(data) {
    return this.http.post(`${this.url}user/broadcast`, data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(
            'this is error',
            response);
          return response;
        }
      )
    );
  }
}
