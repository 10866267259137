import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
// declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  tooltip;
  constructor(private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
  }

  logout(event) {
    event.preventDefault();
    this.authenticationService.logout();
  }
}
