import {ValidationErrors, AbstractControl} from '@angular/forms';
// import {AgentService} from '../services/agent.service';
import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
@Injectable()
export class EmailValidators {
  debouncer: any;

  constructor() {
    // const agentService: agentService = new agentService();
  }

  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return {cannotContainSpace: true};
    } else {
      return null;
    }
  }

  shouldBeUnique(control: FormControl): any {

    clearTimeout(this.debouncer);

    return new Promise(resolve => {

      this.debouncer = setTimeout(() => {

        // this.agentService.existsEmail(control.value).subscribe((res) => {
        //   if(res) {
        //     resolve({shouldBeUnique: true});
        //   }
        // }, (err) => {
        //   resolve(null);
        // });

      }, 1000);

    });
  }
}
