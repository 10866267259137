import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechnicianService {

  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }

  getAllTechnicians(page, perPage) {
    return this.http.get(this.url + `technician?page=${page}&perPage=${perPage}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getAllAvailableTechnicians() {
    // const parameter = new HttpParams().set('availableParams', '0');
    return this.http.get(this.url + `technician?availableParams=1`).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  getTechnician(id) {
    return this.http.get(`${this.url}technician/${id}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response['technician'];
        }
      )
    );
  }

  getAllTechniciansCount() {
    return this.http.get(this.url + 'technician/count').pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getAllTechniciansCountWithType(type) {
    const parameter = new HttpParams().set('typeParams', type);
    return this.http.get(this.url + 'technician/count', {params: parameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(response);
          return response;
        }
      )
    );
  }

  getFormData(object) {
    const formData = new FormData();
    console.log(object);
    Object.keys(object).forEach(key => {
      console.log(key);
      console.log(object[key]);
      formData.append(key, object[key]);
      console.log(formData.has(key));
      // formData.append('me', 'you');
      // console.log(formData.values());
    });
    return formData;
  }
  addServicemen(data, file) {
   // const data = this.getFormData(dataComing);
    return this.http.post(this.url + 'technician/register-technician', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(response);
          return response;
        }
      )
    );
  }
  updateTechnician(data) {
    return this.http.put(`${this.url}technician/${data._id}`, data).pipe(
      map(
        (response) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  searchTechnicians(data) {
    return this.http.get(this.url + 'technician', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
}
