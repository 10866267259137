import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }
  createService(data) {
    return this.http.post(this.url + 'service/create', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  getAllServices() {
    return this.http.get(`${this.url}service/get`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
}
