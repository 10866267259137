import { Injectable } from '@angular/core';

@Injectable()
export class AuthUserHelper {

    constructor() { }

    setToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    removeToken() {
        sessionStorage.removeItem('token');
    }

    isAuthenticated( ) {
        if ( this.getToken()) {
          return true;
        }
        return false;
    }
}
