import { Component, OnInit } from '@angular/core';
import {JobService} from '../shared/services/job.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  data = {};
  jobData;
  jobs;
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  constructor(private jobService: JobService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getRecentJobs(params.page || '1', this.perPage || 10);
    });
  }

  getRecentJobs(page, perPage) {
    this.jobService.getAllJobs(page, perPage).subscribe(
      response => {
        this.jobData = response;
        // console.log(this.jobData);
        this.jobs = this.jobData.data;
        this.pager = this.jobData.pagination;
        this.pageOfItems = this.jobData.pageOfItems;
      });
  }


}
