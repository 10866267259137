import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ServiceService} from '../../shared/services/service.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

// tslint:disable-next-line:class-name
class mentModel {
  name: string;
}

@Component({
  selector: 'app-add-new-service',
  templateUrl: './add-new-service.component.html',
  styleUrls: ['./add-new-service.component.css']
})
export class AddNewServiceComponent implements OnInit {
  response;
  model = new mentModel();
  @ViewChild('serviceForm', {static: false}) form;
  errorMessage: string;
  clicked: boolean = false;

  constructor(private serviceService: ServiceService, private router: Router, private toastrService: ToastrService) { }

  ngOnInit() {
  }
  onSubmit() {
    this.clicked = true;
    console.log(this.form.value);
    this.serviceService.createService(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          return this.router.navigate(['/services']);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>)  => {
        this.errorMessage = error.statusText;
        console.log(error);
        this.clicked = false;
      });
  }


}
