import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as Chart from 'chart.js';
import {GraphService} from '../../shared/services/graph.service';
import {JobService} from '../../shared/services/job.service';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
  data;

  constructor(private graphService: GraphService, private jobService: JobService) {
  }

  completed = {status: 'completed'};
  cancelled = {status: 'cancelled'};
  lineChart;
  Label: any[];
  Paid: number[];
  Failed: number[];
  Added: number[];
  myDoughnutChart;
  txt;
  totalCancelledJobs;
  cancelledJobs;
  totalCompletedJobs;
  CompletedJobs;

  async ngOnInit() {
    this.getCancelledJobs();
    // this.getJobsCompletedCount();

    // this.CompletedJobs = this.getJobsCompletedCount();
    // console.log(await this.getJobsCompletedCount());
    // console.log(this.getCancelledJobs());
    // this.transactionService.statistics().subscribe(
    //   data => {
    //     this.data = data;
    const canvas: any = document.getElementById('myDoughnutChartCanvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    const dataset = {
      datasets: [
        {
          label: 'My First Dataset',
          // data: [this.data.new_count, this.data.successful_count, this.data.failed_count, this.data.processing_count],
          data: [45, 94, this.CompletedJobs, this.cancelledJobs],
          backgroundColor: [
            '#55D8FE',
            '#A3A0FC',
            '#41AC88',
            '#FF6A6A'
          ],
          hoverOffset: 4,
          hoverBackgroundColor: [
            '#9E9E9E',
            '#208a27',
            '#FC0021',
            '#F1C40F'
          ],
        }],
      labels: [
        'Customers',
        'Job Request',
        'Completed',
        'Cancelled'
      ],
    };
    this.myDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: dataset,
      options: {
        layout: {
          padding: 0
        },
        plugins: {
          title: {
            display: false,
            position: 'bottom',
            text: 'Custom Chart Title'
          }
        }
      }
    });
    //   }
    // );
  }


  getCancelledJobs() {
    this.jobService.getJobsCount(this.cancelled).subscribe(
      data => {
        this.totalCancelledJobs = data;
        // this.cancelledJobs = this.totalCancelledJobs.count;
        return this.totalCancelledJobs.count;
      });
  }

   async getJobsCompletedCount() {
    this.jobService.getJobsCount(this.completed).subscribe(
      async data => {
       this.totalCompletedJobs = await data;
      //  console.log(this.totalCompletedJobs);
       return this.totalCompletedJobs.count;
      });
  }

}
