import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
@Input() pager;
@Input() link;
  constructor() { }

  ngOnInit() {
    (this.pager.currentPage === undefined) ? this.pager.currentPage = 1 : this.pager.currentPage = this.pager.currentPage;
  }
  checkPage(i) {
    if (i === this.pager.currentPage) {
      return true;
    }
    return false;
  }
}
