// import { Injectable } from '@angular/core';
// import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
// import {environment} from '../../../environments/environment';
// import {map} from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class CorporateService {
//   url;
//   constructor(private http: HttpClient) {
//     this.url = environment.baseApiUrl + '/corporate/';
//   }
 
//   getAllCorporate() {
//     return this.http.get(this.url + `/all-corporate`).pipe(
//       map(
//         (response: HttpResponse<any>) => {
//           return response;
//         }
//       )
//     );
//   }



// }


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; // Adjusted import
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CorporateService {
  url: string; // Define type for url
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'corporate/';
  }
 
  getAllCorporate() {
    return this.http.get(this.url + `all-corporate`).pipe(
      map(
        (response: any) => { // Removed HttpResponse<any> casting
          return response;
        }
      )
    );
  }
}


