import {Component, OnInit, ViewChild} from '@angular/core';
import {JobService} from '../shared/services/job.service';
import {TechnicianService} from '../shared/services/technician.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
class SearchModel {
  search: string;
}
@Component({
  selector: 'app-servicemen',
  templateUrl: './servicemen.component.html',
  styleUrls: ['./servicemen.component.css']
})
export class ServicemenComponent implements OnInit {
  response;
  technicians;
  techniciansData;
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  model = new SearchModel();
  clicked;
  @ViewChild('searchForm', {static: false}) form;
  constructor(private technicianService: TechnicianService,
              private http: HttpClient,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getAllTechnicians(params.page || '1', this.perPage || 10);
    });
  }

  getAllTechnicians(page, perPage) {
    this.technicianService.getAllTechnicians(page, perPage).subscribe(
      response => {
        this.response = response;
        this.pager = this.response.pagination;
        this.pageOfItems = this.response.pageOfItems;
        this.technicians = this.response.data;
      });
  }
  onSearch() {
    // console.log(this.form.value);
    const data = this.form.value;
    // this.jobService.searchAllJobs(data, 1, 10).subscribe(
    this.technicianService.searchTechnicians(data).subscribe(
      response => {
        this.response = response;
        this.technicians = this.response.data;
        this.pager = this.response.pagination;
        this.pageOfItems = this.response.pageOfItems;
      });
  }
  pperPage(event) {
    this.perPage = event;
    this.getAllTechnicians(1, event);
  }

}
