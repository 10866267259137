import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JobService} from '../shared/services/job.service';
import {BillingService} from '../shared/services/billing.service';
import {TechnicianService} from '../shared/services/technician.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {
job;
bills;
parts;
equipments;
bookingId:any = null;
message:any = '';
  servicing;
  tech;
  constructor(private route: ActivatedRoute, private router: Router,
              private jobService: JobService, private billService: BillingService
              ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.bookingId = id;
      this.getJobDetails(id);
      this.getJobBillingDetails(id);
    });
  }
  terminateJob(){
    (<HTMLButtonElement>document.getElementById("cancelJob")).disabled = true;
    (<HTMLButtonElement>document.getElementById("cancelJob")).style.cursor = 'not-allowed';
    this.jobService.terminateJob(this.bookingId).subscribe(
      (data:any) => {
        console.log(data);
      //   {
      //     "status": "Success",
      //     "code": 200,
      //     "message": "Booking successfully terminated",
      //     "bookingExist": {
      //         "_id": "6336c64ca9f4f99d3d1fe11f",
      //         "name": "Air Conditioner",
      //         "user": "Ben Benj",
      //         "user_image": "",
      //         "date": "Fri Sep 30 2022 08:40:25 GMT+0000 (Coordinated Universal Time)",
      //         "bookingId": "6C5F18",
      //         "time": "Morning",
      //         "service": "622be2e51b6f2c9f3a5a5c2e",
      //         "description": "damaged air filter",
      //         "owner": "6336b216a9f4f99d3d1fd843",
      //         "frequency": null,
      //         "location": "322 ikorodu crescent, dolphin estate, Ikoyi",
      //         "assigned": false,
      //         "status": "Cancelled",
      //         "assistantTechnicianId": [],
      //         "assistantRequest": true,
      //         "userPhone": "2348022441780",
      //         "reassignmentRequest": false,
      //         "reassigned": false,
      //         "createdAt": "2022-09-30T10:34:52.459Z",
      //         "updatedAt": "2022-11-11T08:39:33.671Z",
      //         "__v": 0,
      //         "technician": "Test Tech 3",
      //         "technicianId": "6336bc58a9f4f99d3d1fdccd",
      //         "technicianImage": "",
      //         "technicianPhone": "08108457878",
      //         "assistantRequestNumber": 1,
      //         "assistantRequestStatus": 0,
      //         "bill": "6336c7caa9f4f99d3d1fe25a",
      //         "report": ""
      //     }
      // }
      this.message = data.message;
      if(data.status == 'Success'){
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }else{
        setTimeout(() => {
          this.message = '';
          (<HTMLButtonElement>document.getElementById("cancelJob")).disabled = false;
          (<HTMLButtonElement>document.getElementById("cancelJob")).style.cursor = 'pointer';
        }, 3000);
      }
        // this.job = data;
        // console.log("this.job");
        // console.log(this.job);
      }
    );
  }
  getJobDetails(id) {
    this.jobService.getJob(id).subscribe(
      data => {
        this.job = data;
        console.log("this.job");
        console.log(this.job);
      }
    );
  }

  getJobBillingDetails(id) {
    this.billService.getJobBilling(id).subscribe(
      data => {
        this.bills = data;
        if(this.bills.length > 0 ){
          this.equipments = this.bills[0].equipment;
          this.parts = this.bills[0].equipment[0].parts;
          this.servicing = this.bills[0].equipment[0].servicing;
        }
      }
    );
  }

}
