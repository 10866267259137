

// import { Component, OnInit } from '@angular/core';
// import { CorporateService } from '../shared/services/corporate-registration.service';
// import { HttpClient } from '@angular/common/http';
// import { ActivatedRoute } from '@angular/router';

// @Component({
//   selector: 'app-corporate-registration',
//   templateUrl: './corporate-registration.component.html',
//   styleUrls: ['./corporate-registration.component.css']
// })
// export class CorporateRegistrationComponent implements OnInit {
//   response;
//   panels: boolean[] = [false, false, false];
//   activePanels: number[] = [];

//   constructor(
//     private corporateService: CorporateService,
//     private http: HttpClient,
//     private route: ActivatedRoute
//   ) {}

//   ngOnInit() {
//     this.route.queryParams.subscribe(params => {
//       // You seem to be calling getAllUsers method, but it's not defined in your component
//       // Assuming you want to call a method from the corporateService instead
//       // Adjust this line according to your actual requirement
//       this.corporateService.getAllCorporate();
//     });
//   }

//   getAllCorporate() {
//     console.log("Attempting to fetch corporate data...");
//     this.corporateService.getAllCorporate().subscribe(
//       response => {
//         this.response = response; 
//         console.log(this.response); // Log the response to the console
//       },
//       error => {
//         console.error("Error fetching corporate data:", error); // Log any errors to the console
//       }
//     );
//   }


//   togglePanel(panelNumber: number) {
//     const index = this.activePanels.indexOf(panelNumber);
//     if (index === -1) {
//       this.activePanels.push(panelNumber);
//     } else {
//       this.activePanels.splice(index, 1);
//     }
//   }
// }


import { Component, OnInit } from '@angular/core';
import { CorporateService } from '../shared/services/corporate-registration.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-corporate-registration',
  templateUrl: './corporate-registration.component.html',
  styleUrls: ['./corporate-registration.component.css']
})
export class CorporateRegistrationComponent implements OnInit {
  response: any; // Define type for response
  panels: boolean[] = [false, false, false];
  activePanels: number[] = [];
  allCorporates;

  constructor(
    private corporateService: CorporateService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Call the method inside ngOnInit
      this.getAllCorporate(); // Call the correct method
    });
  }

  getAllCorporate() {
    this.corporateService.getAllCorporate().subscribe(
      response => {
        this.response = response; 
        this.allCorporates = this.response.allCorporates;
         // Log the response to the console
      },
      error => {
        console.error("Error fetching corporate data:", error); // Log any errors to the console
      }
    );
  }

  togglePanel(panelNumber: number) {
    const index = this.activePanels.indexOf(panelNumber);
    if (index === -1) {
      this.activePanels.push(panelNumber);
    } else {
      this.activePanels.splice(index, 1);
    }
  }
}

