import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BillingService} from '../../shared/services/billing.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-price',
  templateUrl: './edit-price.component.html',
  styleUrls: ['./edit-price.component.css']
})
export class EditPriceComponent implements OnInit {

  @Input() model;
  services;
  response;
  @ViewChild('editPriceForm', {static: false}) form;
  clicked: boolean = false;

  constructor(private billService: BillingService, private toastrService: ToastrService) { }

  ngOnInit() {
  }
  onEditSubmit() {
    this.billService.editPrice(this.form.value).subscribe(
      response => {
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          // this.form.reset();
        } else {
          this.toastrService.error(this.response.message);
        }
      });
  }
}
