import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {JobService} from '../shared/services/job.service';
import {BillingService} from '../shared/services/billing.service';
import {TechnicianService} from '../shared/services/technician.service';
import {ToastrService} from 'ngx-toastr';
import {ServiceService} from '../shared/services/service.service';

class UpdateModel {
  name: string;
  phone: string;
  type: string;
  email: string;
  // tslint:disable-next-line:variable-name
  _id: string;
}
@Component({
  selector: 'app-servicemen-details',
  templateUrl: './servicemen-details.component.html',
  styleUrls: ['./servicemen-details.component.css']
})
export class ServicemenDetailsComponent implements OnInit {
  clicked;
  services;
  technician;
  name;
  jobs;
  jobData;
  jobCount;
  jobCountData;
  id;
  perPage;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  makeEdit = false;
  response;
  model = new UpdateModel();
  @ViewChild('updateForm', {static: false}) form;
  constructor(private route: ActivatedRoute, private router: Router,
              private technicianService: TechnicianService,
              private jobService: JobService,
              private billService: BillingService,
              private serviceService: ServiceService,
              private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id'); this.id = params.get('id');
      // console.log(id);
      this.getTechnicianDetails(id);
      this.getTechnicianJobsCount({technician: id});
    });
    this.route.queryParams.subscribe(params => {
      this.getTechnicianBookingDetails(this.id, params.page || '1', this.perPage || 10);
    });
    this.getAllServices();
  }

  getTechnicianDetails(id) {
    this.technicianService.getTechnician(id).subscribe(
      data => {
        this.technician = data;
        // console.log( this.technician);
        this.name = this.technician.fullName;
        this.name = this.name.split(' ');
      }
    );
  }
  getTechnicianBookingDetails(techid, page, perPage) {
    this.jobService.getTechnicianBookingDetails(techid, page, perPage).subscribe(
      data => {
        this.jobData = data;
        this.jobs = this.jobData.data;
        // console.log( this.jobData);
        this.pager = this.jobData.pagination;
        this.pageOfItems = this.jobData.pageOfItems;
      }
    );
  }
  getTechnicianJobsCount(id) {
    this.jobService.getJobsCount(id).subscribe(
      data => {
        this.jobCountData = data;
        this.jobCount = this.jobCountData.count;
      });
  }
  onUpdateSubmit() {
    this.clicked = true;
    // console.log(this.form.value);
    this.technicianService.updateTechnician(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success('Updated Successfully');
          this.router.navigate(['servicemen']);
        } else {
          this.toastrService.error(this.response.message);
        }
      });
  }
  getAllServices() {
    this.serviceService.getAllServices().subscribe(
      response => {
        this.response = response;
        this.services = this.response.data;
      });
  }


}
