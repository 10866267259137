import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { BottombarComponent } from './components/bottombar/bottombar.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import {AuthUserHelper} from './helpers/auth-user.helper';
import { AuthenticationService } from './services/authentication.service';
import {ExcelService} from './services/excel.service';
import {GraphService} from './services/graph.service';
import {FirstwordPipe} from './components/topbar/firstword.pipe';
import { AssignServicemenComponent } from './components/assign-servicemen/assign-servicemen.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PerpageComponent } from './components/perpage/perpage.component';
import {CsvService} from './services/csv.service';


@NgModule({
  declarations: [SidebarComponent, TopbarComponent, BottombarComponent, FirstwordPipe, AssignServicemenComponent, PaginationComponent, PerpageComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
    exports: [
        SidebarComponent, TopbarComponent, BottombarComponent, AssignServicemenComponent, PaginationComponent, PerpageComponent,
    ],
  providers: [AuthUserHelper, AuthGuardService,  AuthenticationService, GraphService, ExcelService, CsvService]
})


export class SharedModule { }
