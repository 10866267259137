import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthUserHelper } from '../helpers/auth-user.helper';

@Injectable()
export class AuthorizationInterceptorService implements HttpInterceptor {

  constructor(private authUser: AuthUserHelper, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token  = this.authUser.getToken();
    const newRequest = req.clone({
        setHeaders: {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json'}
    });
    return next.handle(newRequest).pipe(
        tap(
            (success) => {},
            (error) => {
                if (error.status === 401 ) {
                    this.router.navigate(['/login']);
                }
            }
        )
    );
  }


}
