import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {BillingService} from '../../shared/services/billing.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

// tslint:disable-next-line:class-name
class mentModel {
  category: string;
  service: string;
  name: string;
  price: string;
}
@Component({
  selector: 'app-add-new-price',
  templateUrl: './add-new-price.component.html',
  styleUrls: ['./add-new-price.component.css']
})
export class AddNewPriceComponent implements OnInit {
  response;
  model = new mentModel();
  @ViewChild('priceForm', {static: false}) form;
  errorMessage: string;
  clicked: boolean = false;
  @Input() services;
  @Input() serviceSelected;

  constructor(private billService: BillingService, private toastrService: ToastrService,
              private router: Router) { }
  ngOnInit() {
    this.model.service = this.serviceSelected;
  }

  addNewPrice() {
    this.clicked = true;
    console.log(this.form.value);
    this.billService.createPrice(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          this.form.reset();
          // return this.router.navigate(['/services']);
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>)  => {
        this.errorMessage = error.statusText;
        console.log(error);
        this.clicked = false;
      });
  }
  resetFilter() {
    this.form.reset();
    // this.onFormReset.emit(true);
  }

}
