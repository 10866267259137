import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
// import { Workbook } from 'exceljs/dist/exceljs.min.js';
// import { Workbook } from 'exceljs';
// import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable()

export class ExcelService {
  today: any;
  dd: any;
  mm: any;
  yyyy: any;
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  // exportExcel() {
  //
  //   const workbook = new Workbook();
  //   const worksheet = workbook.addWorksheet('ProductSheet');
  //
  //   workbook.xlsx.writeBuffer().then((data) => {
  //     const blob = new Blob([data], { type: EXCEL_TYPE});
  //     FileSaver.saveAs(blob, 'ProductData.xlsx');
  //   });
  // }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + this.formatDate() + EXCEL_EXTENSION);
  }

  private formatDate() {
    this.today = new Date();
    this.dd = this.today.getDate();

    this.mm = this.today.getMonth() + 1;
    this.yyyy = this.today.getFullYear();
    if (this.dd < 10) {
      this.dd = '0' + this.dd;
    }

    if (this.mm < 10) {
      this.mm = '0' + this.mm;
    }
    const time = this.today.getHours() + ':' + this.today.getMinutes() + ':' + this.today.getSeconds();
    return  this.dd + '-' + this.mm + '-' + this.yyyy + '|' + time;
  }
}
