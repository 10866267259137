import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import {HttpResponse} from '@angular/common/http';
import {AuthUserHelper} from '../shared/helpers/auth-user.helper';
import {logger} from 'codelyzer/util/logger';

class LoginModel {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  response;
  model = new LoginModel();
  @ViewChild('loginForm', {static: false}) form;
  errorMessage: string;
  clicked: boolean = false;
  constructor(private authenticationService: AuthenticationService, private router: Router,
              private authUser: AuthUserHelper) { }

  ngOnInit() {

  }

  onSubmit() {
    this.clicked = true;
    this.authenticationService.authenticate(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        if (this.response.code === 200) {
          const token  = this.response.user.token;
          this.authUser.setToken(token);
          return this.router.navigate(['/dashboard']);
        } else {
          this.errorMessage = this.response.message;
        }

      },
  (error: HttpResponse<any>)  => {
            const msg = 'Invalid email/password, try again or try forget password to get new password.';
            this.errorMessage = (error.status === 422) ? msg : error.statusText;
            // console.log(error);
            this.clicked = false;
      });
  }

}
