import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LoginComponent} from '../login/login.component';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from '../dashboard/dashboard.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import {NotfoundComponent} from '../notfound/notfound.component';
import {JobsComponent} from '../jobs/jobs.component';
import {ServicesComponent} from '../services/services.component';
import {ServicemenComponent} from '../servicemen/servicemen.component';
import {CustomersComponent} from '../customers/customers.component';
import {AccountingComponent} from '../accounting/accounting.component';
import {BroadcastComponent} from '../broadcast/broadcast.component';
import {JobDetailsComponent} from '../job-details/job-details.component';
import {ServicemenDetailsComponent} from '../servicemen-details/servicemen-details.component';
import {CustomerDetailsComponent} from '../customer-details/customer-details.component';
import {CorporateRegistrationComponent} from '../corporate-registration/corporate-registration.component';


const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'jobs',
    component: JobsComponent,
  },
  {
    path: 'jobs/:id',
    component: JobDetailsComponent,
  },
  {
    path: 'servicemen',
    component: ServicemenComponent,
  },
  {
    path: 'servicemen/:id',
    component: ServicemenDetailsComponent,
  },
  {
    path: 'customers',
    component: CustomersComponent,
  },
  {
    path: 'corporate-registration',
    component: CorporateRegistrationComponent,
  },
  {
    path: 'customers/:id',
    component: CustomerDetailsComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'accounting',
    component: AccountingComponent,
  },
  {
    path: 'broadcast',
    component: BroadcastComponent,
  },
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: true, onSameUrlNavigation: 'reload'}), CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class RoutingModule { }
