import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }
  getAllJobsCount() {
    return this.http.get(this.url + 'booking/count/count').pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  terminateJob(id) {
    return this.http.get(`${this.url}booking/terminate/${id}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response;
        }
      )
    );
  }
  getAllJobs(page, perPage) {
    return this.http.get(this.url + `booking?page=${page}&perPage=${perPage}`).pipe(

      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getAllJobsBatch() {
    const parameter = new HttpParams().set('assistantRequestParams', '1');
    return this.http.get(this.url + `booking`, {params: parameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getAllJobsAccount(page, perPage) {
    return this.http.get(this.url + `bill/accounting?page=${page}&perPage=${perPage}`).pipe(

      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  searchAllJobs(search, page, perPage) {
    const parameter = new HttpParams().set('search', search);
    return this.http.get(this.url + `booking?page=${page}&perPage=${perPage}`, {params: parameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'][0];
        }
      )
    );
  }

  getJob(id) {
    return this.http.get(`${this.url}booking/${id}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  assignServicemen(data) {
    return this.http.put(this.url + 'booking/assign', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  assignAssistant(data) {
    return this.http.put(this.url + 'booking/assign-assistant', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  reassignServicemen(data) {
    return this.http.put(this.url + 'booking/reassign', data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  getJobsCount(data) {
    return this.http.get(this.url + 'booking/count/count', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getTechnicianBookingDetails(techid, page, perPage) {
    const parameter = new HttpParams().set('technician', techid);
    return this.http.get(this.url + `booking?page=${page}&perPage=${perPage}`,{params: parameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  getUserBookingDetails(userid, page, perPage) {
    const parameter = new HttpParams().set('user', userid);
    return this.http.get(this.url + `booking?page=${page}&perPage=${perPage}`,{params: parameter}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  searhUserJobs(data) {
    return this.http.get(this.url + 'booking', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  filterByDate(data) {
    return this.http.get(this.url + 'booking', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }
  searchJobs(data) {
    return this.http.get(this.url + 'booking', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

}
