import { Component, OnInit } from '@angular/core';
import {JobService} from '../../shared/services/job.service';
import {UserService} from '../../shared/services/user.service';
import {TechnicianService} from '../../shared/services/technician.service';

@Component({
  selector: 'app-customer-stat',
  templateUrl: './customer-stat.component.html',
  styleUrls: ['./customer-stat.component.css']
})
export class CustomerStatComponent implements OnInit {
  userData;
  userCount;
  constructor(private jobService: JobService, private userService: UserService, private technicianService: TechnicianService) { }

  ngOnInit() {
    this.getAllCustomerCount();
  }

  getAllCustomerCount() {
    this.userService.getAllUsersCount().subscribe(
      response => {
        this.userData = response;
        this.userCount = this.userData.count;
      });
  }

}
