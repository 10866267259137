import {Component, Input, OnInit} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import { JobService } from '../../services/job.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})

export class TopbarComponent implements OnInit {
  details;
  booking;
  @Input() page;
  async ngOnInit() {
    // this.getDetails();
    const res:any = await this.jobService.getAllJobsBatch().toPromise();
    if(res.code == 200){
      this.booking = res.data;
    }
    setInterval( async ()=>{
      const res:any = await this.jobService.getAllJobsBatch().toPromise();
      if(res.code == 200){
        this.booking = res.data;
      }
    },10000)
  }
  constructor(private authenticationService: AuthenticationService,
    private jobService: JobService,
    private router: Router) { }
  logout(event) {
    event.preventDefault();
    this.authenticationService.logout();
  }
  // async accOrDen(bookingId:any, technicianId:any, statusNumber:any, nt:any){
  //   const res:any = await this.jobService.assignAssistant(
  //     {
  //       bookingId,
  //       technicianId,
  //       statusNumber
  //     }
  //   ).toPromise();
  //   if(res.code == 200){
  //     nt.assigned = true;
  //   }
  // }
  async accOrDen(bookingId:any){
    this.goTo(bookingId)
  }
  time_ago(time) {
    time = new Date(time);
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'ago',
      list_choice = 1;

    if (seconds == 0) {
      return 'Just now'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (seconds < format[0]) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return time;
  }
  goTo(id){
    this.router.navigate([`jobs/${id}`])
  }

}
