import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {JobService} from '../../shared/services/job.service';
import { DatePipe } from '@angular/common';
class FilterModel {
  dateFrom;
  dateTo;
}
@Component({
  selector: 'app-job-filter',
  templateUrl: './job-filter.component.html',
  styleUrls: ['./job-filter.component.css']
})
export class JobFilterComponent implements OnInit {
  @ViewChild('filteredForm', {static: false}) form;
  @Output('closeFilter') closeFilter = new EventEmitter();
  @Output('filteredJobs') filteredJob = new EventEmitter();
  @Output('onFormReset') onFormReset = new EventEmitter();
  model = new FilterModel();
  display: boolean = false;
  clicked: boolean = false;
  details;
  dates = {today: '', yesterday: '', lastMonth: '',  lastThreeMonths: '', beginThisMonth: ''};

  constructor(fb: FormBuilder, private jobService: JobService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.getDates();
  }

  onToggle() {
    this.display = !this.display;
  }

  onFilterByDate() {
    this.clicked = true;
    // console.log(this.form.value);
    this.jobService.filterByDate(this.form.value).subscribe(
      data => {
        this.clicked = false;
        // console.log(data);
        this.filteredJob.emit(data);
      }
    );
  }

  onToday() {
    this.clicked = true;
    this.model.dateFrom = this.dates.today;
    this.model.dateTo = new Date();
    this.jobService.filterByDate(this.model).subscribe(
      data => {
        this.clicked = false;
        this.filteredJob.emit(data);
      }
      );
  }

  onYesterday() {
    this.clicked = true;
    this.model.dateFrom = this.dates.yesterday;
    this.model.dateTo = this.dates.today;
    this.jobService.filterByDate(this.model).subscribe(
      data => {
        this.clicked = false;
        this.filteredJob.emit(data);
      }
      );
  }

  onThisMonth() {
    this.clicked = true;
    this.model.dateFrom = this.dates.beginThisMonth;
    this.model.dateTo = this.dates.today;
    this.jobService.filterByDate(this.model).subscribe(
      data => {
        this.clicked = false;
        this.filteredJob.emit(data);
      }
      );
  }

  onLastMonth() {
    this.clicked = true;
    this.model.dateFrom = this.dates.lastMonth;
    this.model.dateTo = this.dates.today;
    this.jobService.filterByDate(this.model).subscribe(
      data => {
        this.clicked = false;
        this.filteredJob.emit(data);
      }
      );
  }

  onLastThreeMonths() {
    this.clicked = true;
    this.model.dateFrom = this.dates.lastThreeMonths;
    this.model.dateTo = this.dates.today;
    this.jobService.filterByDate(this.model).subscribe(
      data => {
        this.clicked = false;
        this.filteredJob.emit(data);
      }
      );
  }

  pipeFormat(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  getDates() {
    this.clicked = true;
    const date  = new Date();
    const today = this.pipeFormat(date);

    const yester = new Date();
    yester.setDate(yester.getDate() - 1);
    const yesterday = this.pipeFormat(yester);

    const beginTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const beginThisMonth = this.pipeFormat(beginTheMonth);

    const lastMonths = new Date();
    lastMonths.setMonth(lastMonths.getMonth() - 1);
    lastMonths.setDate(1);
    const lastMonth = this.pipeFormat(lastMonths);

    const lastThreeMonth = new Date();
    lastThreeMonth.setMonth(lastThreeMonth.getMonth() - 3);
    lastThreeMonth.setDate(1);
    const lastThreeMonths = this.pipeFormat(lastThreeMonth);
    this.dates = {today, yesterday, beginThisMonth, lastMonth, lastThreeMonths};
  }
  onCloseFilter() {
    this.closeFilter.emit(false);
    this.display = !this.display;
  }

  resetFilter() {
    this.form.reset();
    this.onFormReset.emit(true);
  }
}
