import { Component, OnInit } from '@angular/core';
import {BillingService} from '../shared/services/billing.service';
import {ServiceService} from '../shared/services/service.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  page = 1;
  service;
  category = 'parts';
  priceData;
  prices;
  services;
  response;
  choose: boolean;
  selectedIndex = 0;
  editIndex;
  editData;
  mode = {id: ''};
  shouldEdit;
  edit: boolean;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  pageOfItems = [];
  constructor(private billService: BillingService, private serviceService: ServiceService,
              private toastrService: ToastrService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // this.getAllServices(params.page || '1');
      this.page = params.page;
      this.getAllPricing(this.category || 'parts');
    });
    this.getAllServices();
    this.category = 'parts';
  }
  setIndex(index: number) {
    this.selectedIndex = index;
    // this.prices = [{}];
  }
  setService(name) {
    this.service = name;
  }
  onEdit(index: number) {
    this.shouldEdit = true;
    this.editIndex = index;
    this.editData = this.prices[index];
    this.editData['category'] = this.category;
    // console.log(this.editData);
  }

  onDelete(id, category) {
    // console.log(id);
    this.mode.id = id;
    if (confirm('Click OK if you really want to delete this Price')) {
      this.billService.deletePrice(this.mode, category).subscribe(
        response => {
          this.response = response;
          if (this.response.code === 200) {
            this.toastrService.success(this.response.message);
          } else {
            this.toastrService.error(this.response.message);
          }
        });
    }
  }
  getAllPricing(categoryy) {
    this.category = categoryy;
    // console.log(this.category);
    this.billService.getAllPricing({category: categoryy, service: this.service, page: this.page}).subscribe(
      response => {
        this.priceData = response;
        // console.log(this.priceData);
        this.prices = this.priceData.data;
        this.pager = this.priceData.pagination;
        this.pageOfItems = this.priceData.pageOfItems;
      });
  }

  getAllServices() {
    this.serviceService.getAllServices().subscribe(
      response => {
        this.response = response;
        this.services = this.response.data;
        this.setService(this.services[0].name);
      });
  }


}
