import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {forkJoin, Observable} from 'rxjs';
import {map, catchError } from 'rxjs/operators';
import { AuthUserHelper } from '../helpers/auth-user.helper';

@Injectable()
export class AuthenticationService {

  url;
  constructor(private http: HttpClient, private authUser: AuthUserHelper, private router: Router) {
    this.url = environment.baseApiUrl;
  }


  authenticate(data) {
    return this.http.post(this.url + 'admin/auth/login', data).pipe(
      map((response: HttpResponse<any>) => {
        console.log(response);
        return response;
      })
    );
  }

  handleError(response: HttpErrorResponse) {
    return Observable.throw('Invalid email/password, try again or try forget password to get new password.');
  }

  logout() {
    this.authUser.removeToken();
    this.router.navigate(['/login']);
  }

}
