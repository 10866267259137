import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

// @Injectable()
@Injectable()
export class GraphService {

  url;
  graph;
  Label;
  Paid;
  Added;
  Failed;
  Lab;
  txt;
  txted;
  constructor(private http: HttpClient) {
      this.url = environment.baseApiUrl + 'admin/';
      // if (localStorage.getItem('title')) {
      //   this.url = localStorage.getItem('url') + 'admin/';
      // }
  }

  getAll() {
    return this.http.get(this.url + 'system/transactions/graph').pipe(
      map(
        (response: HttpResponse<any>) => {
          return response['data'];
        }
      )
    );
  }

  getLabel() {
    return this.http.get(this.url + 'system/transactions/graph').pipe(
      map(
        (response: HttpResponse<any>) => {
          this.Label = response['data'];
          this.Paid = [this.Label.Paid[0].Paid, this.Label.Paid[1].Paid, this.Label.Paid[2].Paid,
            this.Label.Paid[3].Paid,  this.Label.Paid[4].Paid,
            this.Label.Paid[5].Paid, this.Label.Paid[6].Paid, this.Label.Paid[7].Paid];

          this.Added = [this.Label.Added[0].Added, this.Label.Added[1].Added, this.Label.Added[2].Added,
            this.Label.Added[3].Added,  this.Label.Added[4].Added,
            this.Label.Added[5].Added, this.Label.Added[6].Added, this.Label.Added[7].Added];

          this.Failed = [this.Label.Failed[0].Failed, this.Label.Failed[1].Failed, this.Label.Failed[2].Failed,
            this.Label.Failed[3].Failed,  this.Label.Failed[4].Failed,
            this.Label.Failed[5].Failed, this.Label.Failed[6].Failed, this.Label.Failed[7].Failed];

          this.Lab = [this.Label.Failed[0].Label, this.Label.Failed[1].Label, this.Label.Failed[2].Label,
            this.Label.Failed[3].Label,  this.Label.Failed[4].Label,
            this.Label.Failed[5].Label, this.Label.Failed[6].Label, this.Label.Failed[7].Label];
          this.graph = {
            Label: this.Lab,
            Added: this.Added,
            Failed: this.Failed,
            Paid: this.Paid
          };
          return this.graph;
        }
      )
    );
  }


}
