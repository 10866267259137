import {Component, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {BroadcastService} from '../../shared/services/broadcast.service';
class SendModel {
  title: string;
  body: string;
  isUser: true;
}
@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.css']
})
export class SendComponent implements OnInit {
  model = new SendModel();
  @ViewChild('sendBroadcastForm', {static: false}) form;
  errorMessage: string;
  clicked: boolean = false;
  response;
  constructor(private toastrService: ToastrService, private broadcastService: BroadcastService) { }

  ngOnInit() {
  }

  onSendBroadcast() {
    this.clicked = true;
    const data = this.form.value;
    if (data.isUser === 'false') {
      delete(data.isUser);
    }
    this.broadcastService.sendBroadcast(this.form.value).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        if (this.response.code === 200) {
          this.toastrService.success(this.response.message);
          this.form.reset();
        } else {
          this.toastrService.error(this.response.message);
        }
      },
      (error: HttpResponse<any>)  => {
        this.errorMessage = error.statusText;
        // console.log(error);
        this.clicked = false;
      });
  }

}
