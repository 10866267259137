import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../shared/services/user.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {JobService} from '../shared/services/job.service';
class SearchModel {
  search: string;
}
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  response;
  users;
  name;
  perPage;
  userData;
  pager = {
    hasPrevious: '',
    prevPage: '',
    hasNext: '',
    next: '',
    total: '',
    pageSize: '',
    lastPage: ''
  };
  userJobCount;
  jobCountData;
  pageOfItems = [];
  model = new SearchModel();
  clicked;
  @ViewChild('searchForm', {static: false}) form;
  constructor(private userService: UserService,
              private jobService: JobService, private http: HttpClient,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.getAllUsers(params.page || '1', this.perPage || 10);
    });
  }

  getAllUsers(page, perPage) {
    this.userService.getAllUsers(page, perPage).subscribe(
      response => {
        this.response = response;
        this.pager = this.response.pagination;
        this.pageOfItems = this.response.pageOfItems;
        this.users = this.response.data;
        // console.log(this.response.data);
      });
  }

  split(fullname) {
   const name = fullname.split(' ');
   return name;
  }

  onSearch() {
    this.clicked = true;
    // console.log(this.form.value);
    const data = this.form.value;
    this.userService.searhUsers(data).subscribe(
      response => {
        this.clicked = false;
        this.response = response;
        // console.log(this.response);
        // console.log(this.response.data);
        this.users = this.response.data;
        this.pager = this.response.pagination;
        this.pageOfItems = this.response.pageOfItems;
      });
  }
  pperPage(event) {
    this.perPage = event;
    this.getAllUsers(1, event);
  }
  getUserJobsCount(id) {
    this.jobService.getJobsCount(id).subscribe(
      data => {
        this.jobCountData = data;
        // console.log(this.jobCountData);
        return this.jobCountData.count;
      });
  }

}

