import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url;
  constructor(private http: HttpClient) {
    this.url = environment.baseApiUrl + 'admin/';
  }
  getTotalAmount(data) {
    return this.http.post(this.url + `user/amount?charge=${data.charge}`, data).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }

  getAllUsersCount() {
    return this.http.get(this.url + 'user/count').pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(response);
          return response;
        }
      )
    );
  }
  getAllUsers(page, perPage) {
    return this.http.get(this.url + `user?page=${page}&perPage=${perPage}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          // console.log(response);
          return response;
        }
      )
    );
  }
  getUser(id) {
    // const parameter = new HttpParams().set('id', id);
    // return this.http.get(this.url + 'user', {params: parameter}).pipe(
    return this.http.get(`${this.url}user/${id}`).pipe(
      map(
        (response: HttpResponse<any>) => {
          console.log(response);
          return response['user'];
        }
      )
    );
  }
  searhUsers(data) {
    return this.http.get(this.url + 'user', {params: data}).pipe(
      map(
        (response: HttpResponse<any>) => {
          return response;
        }
      )
    );
  }


}
