import { Component, OnInit } from '@angular/core';
import {JobService} from '../../shared/services/job.service';
import {UserService} from '../../shared/services/user.service';
import {TechnicianService} from '../../shared/services/technician.service';

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {
  totalJobs: any;
  totalAmount: any;
  total: any;
  totalPendingJobs: any;
  totalUsersCount: any;
  totalUsers: any;
  totalTechs: any;
  totalTechsCount: any;
  pendingJobs: any;
  pending = {status: 'pending'};
  constructor(private jobService: JobService, private userService: UserService, private technicianService: TechnicianService) { }

  ngOnInit() {
    this.getAllJobsCount();
    this.getTotalAmount();
    this.getPendingJobsCount();
    this.getAllUsersCount();
    this.getAllTechnicianCount();
  }

  getAllJobsCount() {
    this.jobService.getAllJobsCount().subscribe(
      data => {
        // console.log(data);
        this.totalJobs = data;
      });
  }
  getPendingJobsCount() {
    this.jobService.getJobsCount(this.pending).subscribe(
      data => {
        this.totalPendingJobs = data;
        this.pendingJobs = this.totalPendingJobs.count;
      });
  }
  getTotalAmount() {
    const send = {charge: 1};
    this.userService.getTotalAmount(send).subscribe(
      data => {
        this.totalAmount = data;
        this.total = this.totalAmount.result[0].total;
      });
  }
  getAllUsersCount() {
    this.userService.getAllUsersCount().subscribe(
      data => {
        this.totalUsers = data;
        this.totalUsersCount = this.totalUsers.count;
      });
  }

  getAllTechnicianCount() {
    this.technicianService.getAllTechniciansCount().subscribe(
      data => {
        this.totalTechs = data;
        this.totalTechsCount = this.totalTechs.count;
      });
  }

}
