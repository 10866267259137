import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit {
  barChart;
  constructor() { }

  ngOnInit() {
    this.getBar();
  }
  getBar() {
    // const labels = Utils.months({count: 7});
    const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      datasets: [{
        label: 'Net Income',
        data: [65, 59, 80, 81, 56, 55, 40, 35],
        backgroundColor: [
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)'
        ],
        borderColor: [
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)',
          'rgba(106,87,160, 0.8)'
        ],
        borderWidth: 1
      }]
    };
    const canvas: any =  document.getElementById('column-chart');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    this.barChart = new Chart(ctx, {
      type: 'bar',
      data,
      options: {
        plugins: {
          title: {
            display: false,
          }
        }
      }
    });
  }

}
