import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import {RoutingModule} from './routing/routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {SharedModule} from './shared/shared.module';
import {DashboardModule} from './dashboard/dashboard.module';
import { httpInterceptorProviders } from './shared/http-interceptors/index';
import {StatModule} from './dashboard/stat/stat.module';
import { UiSwitchModule } from '../../node_modules/ngx-toggle-switch';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import {AngularDraggableModule} from 'angular2-draggable';
import { NotfoundComponent } from './notfound/notfound.component';
import { JobsComponent } from './jobs/jobs.component';
import { ServicemenComponent } from './servicemen/servicemen.component';
import { CustomersComponent } from './customers/customers.component';
import { ServicesComponent } from './services/services.component';
import { AccountingComponent } from './accounting/accounting.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobStatsComponent } from './jobs/job-stats/job-stats.component';
import { ServicemenStatComponent } from './servicemen/servicemen-stat/servicemen-stat.component';
import { CustomerStatComponent } from './customers/customer-stat/customer-stat.component';
import { ServicemenDetailsComponent } from './servicemen-details/servicemen-details.component';
import { ViewBillModalComponent } from './job-details/view-bill-modal/view-bill-modal.component';
import { AddNewServiceComponent } from './services/add-new-service/add-new-service.component';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';
import { AddNewPriceComponent } from './services/add-new-price/add-new-price.component';
import { EditPriceComponent } from './services/edit-price/edit-price.component';
import { JobFilterComponent } from './jobs/job-filter/job-filter.component';
import { CreateCalloutFeeComponent } from './services/create-callout-fee/create-callout-fee.component';
import { DatePipe } from '@angular/common';
import { AccountStatsComponent } from './accounting/account-stats/account-stats.component';
import { SendComponent } from './broadcast/send/send.component';
import { CorporateRegistrationComponent } from './corporate-registration/corporate-registration.component';
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    NotfoundComponent,
    JobsComponent,
    ServicemenComponent,
    CustomersComponent,
    ServicesComponent,
    AccountingComponent,
    BroadcastComponent,
    JobDetailsComponent,
    JobStatsComponent,
    ServicemenStatComponent,
    CustomerStatComponent,
    ServicemenDetailsComponent,
    ViewBillModalComponent,
    AddNewServiceComponent,
    CustomerDetailsComponent,
    AddNewPriceComponent,
    EditPriceComponent,
    JobFilterComponent,
    CreateCalloutFeeComponent,
    AccountStatsComponent,
    SendComponent,
    CorporateRegistrationComponent
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DashboardModule,
    StatModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    AngularDraggableModule,
    HttpClientModule

  ],
  providers: [httpInterceptorProviders, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
